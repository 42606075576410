import Velocity from 'velocity-react/lib/velocity-animate-shim'
import { Router } from '>/server/routes'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'
import getConfig from 'next/config'
import { isEmpty } from 'ramda'
import urlSlug from 'url-slug'
import { Trans } from '>/i18n'
import Hashids from 'hashids'

import {
  TAGS,
  TAGS_SORT_PATTERN,
  TAG_FILTERS,
  TAG_TO_OMIT,
  MIN_NUMBER_OF_ATTENDEES,
  MAX_NUMBER_OF_ATTENDEES,
  MODAL_NAMES,
  EXPIRATION_TIME_DAILY,
  EXPIRATION_TIME_FER,
  RESERVABLE_TYPES,
  REGULATION_STATUS,
  REFERER_WHITELIST,
  VISA_LINK
} from './consts'

import { GET_CURRENT_FESTIVAL_EDITION, GET_FESTIVAL_EDITION } from '~/utils/queries.gql'
import config from '~/config'
import { FESTIVAL_TYPES } from '~/config/festivalTypes'
import { DEFAULT_FESTIVAL } from '~/config/index'
import { setCookies, getCookie } from '~/utils/cookie'

export const processEnv = (getConfig() || {}).publicRuntimeConfig || {}

export const hasWindow = () => typeof window !== 'undefined'

export const getFestival = (router, domain) => {
  const domainName = hasWindow() ? window.location.host : domain

  let festivalLanding = false
  if (router && router.pathname) {
    festivalLanding = router.pathname.match(/^\/festival\/([a-zA-z]+)/)
  }

  let currentFestival
  if (festivalLanding) {
    currentFestival = { query: festivalLanding[1] }
  } else {
    currentFestival = Object.values(FESTIVAL_TYPES).find(({ url }) => url === domainName)
  }
  return get(currentFestival, 'query') || DEFAULT_FESTIVAL
}

export const getStaticFilePath = (path = '') => {
  let filePath
  try {
    filePath = require(`../../static/${path}`)
  } catch (error) {
    filePath = ''
  }
  return filePath
}

export const isSVG = path => /\.svg$/.test(path)

export const getScaledPath = (path, scale = 2) => path.replace(/\.([\w]*)$/, `@${scale}x.$1`)

export const getRasterImagePath = (path = '') => {
  if (!isSVG(path)) {
    path = getScaledPath(path)
  }
  return getStaticFilePath(path)
}

export const scrollTo = (targetId, configObject = {
  duration: 500,
}) => async (e) => {
  e && e.preventDefault()
  const el = targetId === 'body' ? document.querySelector('body') : document.getElementById(targetId)

  if (el) {
    Velocity(el, 'scroll', configObject)
  } else {
    await Router.pushRoute('/', targetId)
    const el = document.getElementById(targetId)
    Velocity(el, 'scroll', configObject)
  }
}

export const clearFilters = ({ router, resetForm, paramsToStay, initialValues }) => {
  const newQueryObject = {}

  if (typeof paramsToStay !== 'string') {
    paramsToStay.forEach(param => {
      router.query[param] && (newQueryObject[param] = router.query[param])
    })
  } else {
    router.query[paramsToStay] && (newQueryObject[paramsToStay] = router.query[paramsToStay])
  }

  router.push({
    pathname: router.pathname,
    query: newQueryObject
  })
  resetForm(initialValues)
}

export const getObjectFromQueryParam = queryParam => {
  const queryParamArr = queryParam ? queryParam.split('&') : []
  return queryParamArr.reduce((acc, cur) => {
    acc[cur] = true
    return acc
  }, {})
}

export const scrollBarWidth = () => {
  const bodyElement = hasWindow() && document.querySelector('body')
  const fullWidth = hasWindow() && window.innerWidth
  const withoutScrollWidth = bodyElement && bodyElement.clientWidth

  return fullWidth - withoutScrollWidth
}

export const toggleBodyScroll = (
  isModalOpen,
  previousScrollY,
  previousHeaderPosition,
  scrollBarWidth,
  position = 'fixed'
) => {
  if (hasWindow) {
    const htmlElement = document.querySelector('html')
    const headerElement = document.querySelector('.header')

    const isHeaderFixed = previousHeaderPosition === 'fixed'

    if (isModalOpen) {
      const styles = `position:${position}; left:0; right:${scrollBarWidth}px; top:-${previousScrollY}px`
      const headerStyles = `position:absolute; top:${previousScrollY}px`

      htmlElement.style.cssText = styles
      if (headerElement) {
        headerElement.style.cssText = isHeaderFixed ? headerStyles : ''
      }
    } else {
      htmlElement.removeAttribute('style')
      headerElement && headerElement.removeAttribute('style')
      hasWindow() && window.scrollTo(0, previousScrollY)
    }
  }
}

export const priceToString = (price, forceFractions = true) => {
  let priceStr
  if (forceFractions) {
    priceStr = Number(price).toFixed(2)
  } else {
    priceStr = Number(price).toString()
  }
  return priceStr.replace('.', ',')
}

export const redirect = (url, res, { ignoreQueryKeys = [] } = {}) => {
  if (res) {
    res.redirect(url)
  } else {
    const query = Router.router.query
    Router.replace({
      pathname: url,
      query: omit(query, ignoreQueryKeys)
    })
  }
}

export const minutesToHour = minutes => {
  return formatDate({
    date: moment().utc().startOf('day').add(minutes, 'minutes'),
    outputFormat: 'HH:mm'
  })
}

export const filtersCount = (queryParams, deletableFilters) => {
  const allFilters = Object.keys(queryParams)
  const singleFilters = allFilters.filter(item => !deletableFilters.includes(item)).length

  const tagsCount = TAG_FILTERS.reduce((acc, tag) => {
    return acc + (queryParams[tag] ? queryParams[tag].split('&').length : 0)
  }, 0)

  return singleFilters + tagsCount
}

export const viewportWidth = () => {
  if (hasWindow() && typeof window.innerWidth !== 'undefined') {
    return window.innerWidth
  }
}

export const roundPrice = (number) => (isNaN(Number(number)) ? 0 : number).toFixed(2)

export const formatDate = ({ date, inputFormat, outputFormat }) => {
  if (date instanceof moment) {
    return date.parseZone().format(outputFormat)
  } else {
    return moment.parseZone(date, inputFormat).format(outputFormat)
  }
}

export const getFaqRoute = (festivalName) => {
  switch (festivalName) {
    case 'rw':
      return '/restaurantweek/faq'
    case 'fdw':
      return '/finediningweek/faq'
    case 'gw':
    case 'pw':
    case 'bw':
      return 'faq'
    case 'cc':
      return '/bliscyprzystole/faq'
    default:
      return 'faq'
  }
}

export const getFestivalUrl = (festivalName) => {
  switch (festivalName) {
    case 'rw':
      return '/restaurantweek'
    case 'gw':
      return '/ginweek'
    case 'pw':
      return '/pizzaweek'
    case 'fdw':
      return '/finediningweek'
    case 'cc':
      return '/bliscyprzystole'
    case 'bw':
      return '/breakfastweek'
    default:
      return 'main'
  }
}

export const getFestivalRange = ({ startsAt, endsAt }) => {
  let festivalStartingDate

  if (moment(startsAt).isSame(moment(endsAt), 'year')) {
    if (moment(startsAt).isSame(moment(endsAt))) {
      festivalStartingDate = ''
    } else if (moment(startsAt).isSame(moment(endsAt), 'month')) {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D' })
    } else {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D MMMM' })
    }
  } else {
    festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D MMMM YYYY' })
  }
  const festivalEndingDate = (festivalStartingDate ? ' - ' : '')
    + formatDate({ date: endsAt, outputFormat: 'D MMMM YYYY' })

  return (festivalStartingDate + festivalEndingDate)
}

export const getFestivalRangeShort = ({ startsAt, endsAt }) => {
  let festivalStartingDate

  if (moment(startsAt).isSame(moment(endsAt), 'year')) {
    if (moment(startsAt).isSame(moment(endsAt))) {
      festivalStartingDate = ''
    } else if (moment(startsAt).isSame(moment(endsAt), 'month')) {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D' })
    } else {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D.MM' })
    }
  } else {
    festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D.MM.YYYY' })
  }
  const festivalEndingDate = (festivalStartingDate ? ' - ' : '')
    + formatDate({ date: endsAt, outputFormat: 'D.MM' })

  return (festivalStartingDate + festivalEndingDate)
}

export const normalizeDate = (date, inputFormat) => formatDate({ date, inputFormat, outputFormat: 'YYYY-MM-DD' })

export const supportsIntersectionObserver = () => (
  'IntersectionObserver' in window && 'IntersectionObserverEntry' in window
)

export const menuCountEqualsGuests = ({ menus, people }, menusPerPerson = 1) => {
  const menusSum = menus.reduce((sum, menu) => sum + menu, 0)
  return people * menusPerPerson === menusSum
}

export const parseTags = tags => tags && tags.split('&')

export const tagsCategories = (tagsArr = [], field = 'type') => {
  return Array.from(new Set(tagsArr.map(item => item[field])))
}

export const groupTagsByCategory = (tagsArr = [], field = 'type', tagsToOmit = TAG_TO_OMIT) => {
  const categoryArr = tagsCategories(tagsArr, field)

  return categoryArr.reduce((tagsMap, category) => {

    if (!tagsToOmit.includes(category)) {
      tagsMap[category] = tagsArr.filter(item => item[field] === category)
    }

    return tagsMap
  }, {})
}

export const sortTagsCategories = (tagsObject = {}, pattern = TAGS_SORT_PATTERN) => (
  Object.keys(tagsObject)
    .sort((a, b) => pattern.indexOf(a) - pattern.indexOf(b))
    .reduce((acc, curr) => {
      // sorting tags in category by position
      acc[curr] = tagsObject[curr].sort((a, b) => a.position - b.position)
      return acc
    }, {})
)

export const tagsToDisplay = tags => {
  const tagsToOmit = [ TAGS.CATEGORY, TAGS.OCCASION, TAGS.OTHER, ...TAG_TO_OMIT ]
  const { EstablishmentType, Cuisine, Dish, Diet, Atmosphere } = groupTagsByCategory(tags, 'type', tagsToOmit)

  const tagsToDisplay = [
    Cuisine && Cuisine[0],
    Dish && Dish[0],
    Diet && Diet[0],
    Atmosphere && Atmosphere[0]
  ]

  if (EstablishmentType
    && (EstablishmentType.length > 1 || (EstablishmentType[0].name).toLowerCase() !== 'restauracja')) {
    tagsToDisplay.unshift(...EstablishmentType)
  }

  return tagsToDisplay
}

export const queryObjectToTagsFilterVariable = tagsObject => {
  const tags = []

  for (let categoryName in tagsObject) {
    const tagsGroup = tagsObject[categoryName]
    parseTags(tagsGroup).forEach(item => {
      tags.push({ id: item, type: categoryName })
    })
  }
  return tags
}

export const queryCacheExists = (client, query, variables) => {
  try {
    client.readQuery({
      query,
      variables
    })
    return true
  } catch (e) {
    return false
  }
}

export const persistFestivalParam = (query, paramsToPersist, route) => {
  const festivalPersistent = route === 'main' || route === 'faq' || route === 'regulations'

  if (query.festival && festivalPersistent) {
    if (typeof paramsToPersist === 'object') {
      paramsToPersist['festival'] = query.festival
    } else {
      paramsToPersist = {
        festival: query.festival
      }
    }
  }

  return paramsToPersist
}

export const formatHours = (hoursArr = []) => hoursArr
  .reduce((hoursArr, slot) => {
    hoursArr.push({ hour: slot })
    return hoursArr
  }, [])

export const getErrorMessageForGraphError = (error, isRegistration) => {

  switch (error.fieldName) {
    case 'email':
      if (error.errorType === 'taken') {
        return 'errors:already_registered'
      } else {
        return 'errors:email_invalid'
      }
    case 'phone_number':
      if (error.errorType === 'invalid') {
        return 'errors:validations.invalid_phone_number'
      }
      break
    case 'user':
      if (error.errorType === 'unauthorized') {
        return 'errors:sign_in_fail'
      }
      if (error.errorType === 'unverified_user') {
        return isRegistration ? 'errors:unverified_user_register' : 'errors:unverified_user'
      }
      break
    default:
      return 'errors:common_error'
  }
}

export const formatGraphErrors = (errors = []) => {
  const validationErrors = errors.find(error => get(error, 'extensions.code') === 'validation') || {}
  const authErrors = errors.filter(error => (
    get(error, 'extensions.code') && get(error, 'extensions.code') !== 'validation'
  ))

  const formattedErrors = []

  const fieldsWithErrors = get(validationErrors, 'extensions.details')

  if (fieldsWithErrors) {
    Object.values(fieldsWithErrors).map((field, i) => {
      const formattedValidationError = {
        fieldName: Object.keys(fieldsWithErrors)[i],
        errorType: field[0].error,
      }
      formattedErrors.push(formattedValidationError)
    })
  }
  if (!isEmpty(authErrors)) {
    authErrors.map(authError => {
      const formattedAuthError = {
        fieldName: 'user',
        errorType: get(authError, 'extensions.code'),
      }
      formattedErrors.push(formattedAuthError)
    })
  }

  return formattedErrors
}

export const handleReservationError = (error, openModal) => {
  const graphQLErrors = get(error, 'graphQLErrors', [])
  const invalidReservationCode = graphQLErrors.find(error => get(error, 'extensions.code') === 'record_not_found')

  if (invalidReservationCode) openModal(MODAL_NAMES.INVALID_RESERVATION)
  return invalidReservationCode
}

export const handleEmailFieldChange = (event) => {
  event.target.value = event.target.value.trim()
}

export const getFestivalState = async (apolloClient, queryParams, domain) => {
  const festivalName = getFestival({ query: queryParams }, domain)
  const festivalId = queryParams.festivalFilter || queryParams.festivalId

  let data

  if (festivalName) {
    data = await apolloClient.query({
      query: GET_CURRENT_FESTIVAL_EDITION,
      variables: { code: festivalName }
    }).catch(() => { return null })
  } else if (festivalId) {
    data = await apolloClient.query({
      query: GET_FESTIVAL_EDITION,
      variables: { id: festivalId }
    }).catch(() => { return null })
  }

  const festivalData = get(data, 'data.currentFestivalEdition') || get(data, 'data.festivalEdition')

  if (festivalData === null) return null
  return get(festivalData, 'state')
}

export const getFestivalRangeAndPrice = async (apolloClient, code) => {

  let data
  if (code) {
    const { data: festivalData } = await apolloClient.query({
      query: GET_CURRENT_FESTIVAL_EDITION,
      variables: { code },
      skip: !code
    })
    data = festivalData
  }

  if (get(data, 'currentFestivalEdition') === null) return null
  return { startsAt: get(data, 'currentFestivalEdition.startsAt'),
    endsAt: get(data, 'currentFestivalEdition.endsAt'),
    price: get(data, 'currentFestivalEdition.price'),
  }
}

export const formatLocationQuery = location => location && `${location.id}-${location.name}`

export const setLocationAndPeopleQuery = (peopleCount, minPeopleCount, location) => {
  const locationStr = formatLocationQuery(location)

  if (peopleCount !== minPeopleCount) {
    return {
      location: locationStr,
      people: peopleCount
    }
  } else {
    return { location: locationStr }
  }
}

export const getVisaLink = (festivalCode = 'rw') => {
  switch (festivalCode) {
    case 'bw':
      return VISA_LINK.bw

    case 'fdw':
      return VISA_LINK.fdw

    case 'rw':
    default:
      return VISA_LINK.rw
  }
}

export const getPeopleOptions = ({
  minPeopleCount = MIN_NUMBER_OF_ATTENDEES,
  maxPeopleCount = MAX_NUMBER_OF_ATTENDEES,
  lastGuestExtra = false
}) => {
  const peopleArray = []

  for (let i = minPeopleCount; i <= maxPeopleCount; i++) {
    peopleArray.push({
      label: <Trans
        i18nKey={lastGuestExtra && i === maxPeopleCount ? 'common:guests_extra' : 'common:guests'}
        count={i}>
        <span>{{ peopleCount: i }}</span>
      </Trans>,
      value: i
    })
  }

  if (lastGuestExtra) {
    peopleArray.push({
      label: <Trans i18nKey='common:guests_plus' count={maxPeopleCount}>
        <span>{{ peopleCount: maxPeopleCount }}</span>
      </Trans>,
      value: 'extra'
    })
  }

  return peopleArray
}

export const getGraphQLApiUrl = festivalName => config(festivalName).apiUrl + '/graphql'

export const getCitiesList = (regions) => ((!regions || isEmpty(regions))
  ? ''
  : regions.map(region => region.name).join(', ')
)

export const dwTransChanger = festivalName => festivalName === 'dw' ? '_dw' : ''

export const setSalesmanagoCookie = user => {
  const currentId = get(user, 'salesmanagoContactId') || ''
  const lastId = getCookie(null, 'smclient')

  user && currentId !== lastId && setCookies('smclient', currentId, null, {
    expires: moment().add(10, 'years').toDate(),
  })
}

export const getLogo = (festivalName, white = false) => {
  let type = 'club'
  if (festivalName) {
    type = config(festivalName).festival.id
  }
  return white ? `assets/logo/logo-${type}-white.svg` : `assets/logo/logo-${type}.svg`
}

export const termsLabel = ({ privacyPolicy = {}, regulation = {}, t }) => (
  t('register.terms', {
    regulationLink: `/regulation/${regulation.id}`,
    policyLink: `/regulation/${privacyPolicy.id}`
  })
)

export const getExpirationTime = reservation => {
  const type = get(reservation, 'reservable.__typename')
  switch (type) {
    case RESERVABLE_TYPES.DAILY:
      return EXPIRATION_TIME_DAILY
    case RESERVABLE_TYPES.FER:
      return EXPIRATION_TIME_FER
    case RESERVABLE_TYPES.EVENT:
      return EXPIRATION_TIME_FER
    default:
      return null
  }
}

export const presentCheck = (date, hour, comparisonTime) => {
  const slotMoment = moment(`${normalizeDate(date)} 00:00`, 'YYYY-MM-DD HH:mm').minutes(hour)
  return comparisonTime.isSameOrBefore(slotMoment, 'minutes')
}

export const concatSlots = slots => slots && slots.reduce((acc, slot) => {
  acc.push(...slot.possibleSlots)
  return acc
}, [])

export const secondsToMinutes = seconds => {
  const m = Math.floor(seconds / 60)
  const s = (seconds % 60).toString().padStart(2, '0')
  return m + ':' + s
}

export const toSlug = param => urlSlug(param)

export const handleSponsorsLink = ({ selectedFestivalCode, isListingRoute, handleScrollTo }) => {
  const externalUrl = isListingRoute && selectedFestivalCode
  const lang = hasWindow() && localStorage && localStorage.getItem('language')
  let href = '/'

  if (externalUrl) {
    if (config().enableFestivalParam) {
      href = `/?festival=${selectedFestivalCode}&scrl=1&lang=${lang}`
    } else {
      href = `https://${config(selectedFestivalCode).festival.url}/?scrl=1&lang=${lang}`
    }
  }

  return {
    href: externalUrl ? href : undefined,
    onClick: externalUrl ? undefined : e => handleScrollTo('partners-section', 20, e)
  }
}

export const handleRegulationModal = ({ regulationStatus, openModal }) => {
  if (regulationStatus && regulationStatus !== REGULATION_STATUS.ACCEPTED) {
    openModal(MODAL_NAMES.REGULATION, { status: regulationStatus })
  }
}

export const isAddressChanged = (values, reservationAddress) => {
  if (values.street !== reservationAddress.street) return true
  if (values.building !== reservationAddress.houseNo) return true
  if (values.apartment !== reservationAddress.flatNo) return true
  if (values.zip !== reservationAddress.zipCode) return true
  if (values.city !== reservationAddress.city) return true
  if (values.additionalAddressInfo !== reservationAddress.notes) return true
  return false
}

export const isAddressInvalid = (street, houseNo, zipCode, city) => !street || !houseNo || !zipCode || !city
  || (zipCode && !zipCode.match(/^\d{2}-?\d{3}$/g))

export const addLangtoUrl = url => {
  const lang = hasWindow() && localStorage && localStorage.getItem('language')

  if (!lang) return url

  const containsParams = url.match(/\?/)

  return containsParams
    ? `${url}&lang=${lang}`
    : `${url}/?lang=${lang}`
}

export const getConsentValue = (router, referer, consentName) => {
  const isRefererValid = referer && REFERER_WHITELIST.some(item => referer.includes(item))
  const passedParam = router.query.__f

  const consents = {
    cookies: { index: 0, cookieName: 'cookieConsent2' },
    marketing: { index: 1, cookieName: 'marketingConsent' },
    adult: { index: 2, cookieName: 'adult' },
  }

  const consent = consents[consentName]

  const cookieValue = getCookie(null, consent.cookieName)

  if (cookieValue) {
    return true
  } else if (!isRefererValid || !passedParam) {
    return false
  } else {
    const salt = config().salt
    const hashids = new Hashids(salt)
    const decodedParam = hashids.decode(passedParam)
    const isConsentGiven = decodedParam[consent.index]

    if (isConsentGiven) {
      setCookies(consent.cookieName, true, null, { expires: moment().add(10, 'years').toDate() })

      const marketingConsentGiven = decodedParam[1]
      if (consentName === 'cookies' && marketingConsentGiven
        && hasWindow() && window.dataLayer && config().enableAnalytics) {
        window.dataLayer.push({ 'event': 'consent_given' })
        window.dataLayer.push({ 'cookie_consent': 'true' })
      }
    }

    return isConsentGiven
  }
}

export const FESTIVAL_CODE_TO_NEW_CLIENT_SLUG = {
  bw: 'breakfastweek',
  gw: 'ginweek',
  fdw: 'fine-dining-week',
  rw: 'restaurant-week'
}
