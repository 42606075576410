import { TAG_TO_OMIT, TAGS, COMMON_HEADER_LINKS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'Breakfast Week',
  id: 'festival-bw',

  url: 'breakfastweek.pl',
  query: 'bw',

  fb: 'https://www.facebook.com/RestaurantWeekPolska',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@restaurantweek.pl',
  showAd: true,
  chatbotEnabled: true,

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [...COMMON_HEADER_LINKS, HEADER_LINK_NAMES.SIDE_EVENTS]
}
